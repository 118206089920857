@import "./animations.scss";

.nav-item .nav-link.active {
  color: $secondary;
  border-bottom: 1px solid $secondary;
}

a {
  color: $primary;
  text-decoration: none !important;
}

.modal-content {
  background-color: $darkash !important;
}

/* Main Header */
.header {
  background-color: $darkash;
  border-bottom: 2px solid $fineash;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 100000;
}

.ash-container {
  background-color: $darkash;
  border-radius: 20px;
}

.desktop-nav .dropdown-menu,
.desktop-nav .dropdown-menu .dropdown-item {
  background-color: $base;
  color: $primary;
}

.desktop-nav .dropdown-menu .dropdown-item:hover {
  background-color: $primary;
  color: $base;
}

.desktop-toggle.btn-secondary,
.mobile-toggle.btn-secondary,
.desktop-toggle.btn-secondary:focus,
.mobile-toggle.btn-secondary:focus,
.mobile-toggle.btn-secondary:hover {
  color: $primary;
  background-color: $darkash;
  border: none;
}

.mobile-toggle.btn-secondary,
.mobile-toggle.btn-secondary:hover {
  border: 1px solid $primary;
}

.desktop-toggle.btn-secondary:hover,
.desktop-toggle.btn-secondary.focused,
.mobile-toggle.btn-secondary.focused {
  color: $darkash;
  background-color: $primary;
  border: 1px solid $darkash;
}

.desktop-toggle.btn-secondary:focus,
.mobile-toggle.btn-secondary:focus {
  box-shadow: none;
}

.desktop-sub-nav,
.desktop-sub-nav button.desktop-toggle {
  min-width: 100%;
}

.desktop-sub-nav .desktop-toggle-sub.btn-secondary,
.item-link {
  background-color: $base;
  color: $primary;
  border: none;
  border-radius: 0px !important;
}

.desktop-sub-nav .desktop-toggle-sub.btn-secondary:hover,
.item-link:hover {
  background-color: $primary;
  color: $base;
  border: none;
  border-bottom: 1px solid $fineash;
}

.desktop-sub-nav .desktop-toggle-sub.btn-secondary a,
.sub-item-link,
.item-link {
  font-size: 0.7em;
  min-width: 100%;
}

.desktop-sub-nav .desktop-toggle-sub.btn-secondary:hover a,
.desktop-sub-nav .dropdown-item a:hover {
  color: $base;
}

.dropdown-item,
.desktop-toggle-sub {
  border-top: 1px solid $fineash !important;
}

.menu-inner {
  border-bottom: 1px solid $fineash !important;
}

.desktop-sub-nav .icon {
  font-size: 12px;
}

.mobile-nav {
  position: relative;
}

.mobile-nav-body {
  position: absolute;
  top: 3.5em;
  right: -0.75rem;
  background-color: $darkash;
  border-top: 1px solid $fineash;

  .mobile-nav-body-container {
    position: relative;
    background-color: $darkash;
    min-width: 100vw;
    z-index: 10000;
    .card-header {
      background-color: $darkash;
      h6 {
        font-size: 12px;
      }
      .icon {
        font-size: 20px;
        margin-right: 0.15em;
      }
    }
  }
}

/* Hero Section */
.custom-radial-bg {
  background: radial-gradient(
    circle at 73% 50%,
    $base-brown,
    $base-brown 1%,
    $midnight 50%,
    $midnight 90%
  );
}

.hero-image-container {
  width: 100%;
  max-width: 100%;

  .hero-image {
    max-width: 100%;
  }
}

.hero-image-absolute {
  max-width: 100%;
  opacity: 0.25;
  pointer-events: none;
}

.navbar-brand img {
  width: 225px;
}

.hero-card {
  background-color: $fineash;
  max-width: 325px;
  height: auto;
  flex: 1;
  border-radius: 33px;
}

.btn.cta-button {
  background-color: $primary_data;
  width: 90%;
  margin: 0 auto;
}

.text-primary-data {
  color: $primary_data;
}

.custom-layer-bg {
  position: relative;
  background-color: $darkash;
  height: auto;
  padding-bottom: 5%;
  z-index: 0;
}

.transition-layer-node {
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.transition-layer-node img {
  position: relative;
  margin: auto;
  @include animation("translate-bg 360s linear 1");
  height: 75%;
  width: 75%;
}

.service-page {
  .subheader {
    background-color: $base_layer;
    border-radius: 10px;
  }
}

.service-card {
  background-color: $base_layer;
  border-radius: 20px;
  width: 100%;
  max-width: 360px;
  min-width: 310px;

  .service-card-inner {
    background-color: $darkash;
    border-radius: 10px;
    height: 98%;
    display: flex;
    flex-flow: column;

    .image-container {
      overflow: hidden;
      text-align: center;
      position: relative;
      width: 100%;
      min-height: 150px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 2px solid $secondary;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
      }
    }

    .description {
      background-color: $base_layer;
      border-radius: 10px;
      flex: 1;
    }
  }
}

.contact-section {
  background: linear-gradient(
    to bottom right,
    $base-brown 0%,
    $midnight 80%,
    $midnight 100%
  );

  .section {
    background-color: $base_layer;
    border-radius: 10px;
  }
}

.footer {
  background-color: $darkash;
  border-top: 2px solid $fineash;
}

.rotate-up {
  @include animation("rotate-up 0.6s ease 1 0s forwards");
}

.rotate-down {
  @include animation("rotate-down 0.6s ease 1 0s forwards");
}

.preprocess-image {
  min-height: 100%x;
  max-width: 100%;
}

.grid-lines {
  .domain {
    stroke: transparent;
  }
  line {
    stroke: $fineash;
  }
}

.chart-page {
  margin-top: 5em;

  .chart-viewbox {
    background-color: $base;
    border-radius: 10px;
    padding: 1em;
  }
}
.select-input {
  .select-input-element {
    color: $secondary !important;
    border: none;
    background-color: $fineash;
    width: 100%;
    border-radius: 10px;

    &:focus-visible {
      outline: none;
    }
  }
}

.desktop-table-container {
  background-color: $fineash;
}

.table {
  border-color: $base !important;
}

.table > :not(:first-child) {
  border-top: none !important;
}

th {
  color: $secondary;
  padding: 1.15em 0.5em;
  background-color: $darkash !important;
}

div.tooltip {
  position: absolute;
  text-align: left;
  min-width: 30px;
  min-height: 30px;
  padding: 1.5em;
  background: $base;
  border: 0px;
  border-radius: 10px;
  pointer-events: none;
  color: $secondary;
  font-size: 0.7em;
}

.bar-chart-container {
  max-width: 850px !important;
}

.modal-header {
  border-bottom: 1px solid $primary !important;
}

button.close {
  background-color: $primary;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 10rem;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 0.7em;
    border-radius: 0.5rem;
    color: transparent;
    accent-color: #053a5f;
    backgorund: $fineash;
    background: linear-gradient($primary, $primary) 0 / var(--sx) 100% no-repeat,
      $fineash;
  }

  &::-moz-range-track {
    -moz-appearance: none;
    height: 0.7em;
    border-radius: 0.5rem;
    color: transparent;
    accent-color: #053a5f;
    background: $fineash;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -0.15em;
    width: 1em;
    height: 1em;
    background-color: $primary_data;

    border: 1px solid $primary_data;
    border-radius: 1rem;
  }
}

.control-drawer {
  width: 100%;
  display: flex;
  justify-content: end;

  &.show > button.btn-secondary.dropdown-toggle {
    background-color: $fineash;
    color: $secondary;
    box-shadow: none;
  }

  button.btn-secondary:focus {
    box-shadow: none;
  }

  button.btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: $fineash;
    color: $secondary;
    border: none;
    padding: 0.25rem;
    right: 0;
    border-radius: 10px;

    .svg-inline--fa.fa-w-14 {
      font-size: 12px;
      width: 1em;
      font-weight: 100;
    }
  }

  div.dropdown-control-drawer {
    background-color: #282621 !important;
    padding: 0.5em;
  }
}

.chevron {
  position: relative;
  display: block;
  height: 6px; /*Height should be double border thickness*/
}

.chevron::after {
  content: " ";
  display: inline-block;
  position: absolute;
  border-bottom: 0.1em solid $secondary;
  border-right: 0.1em solid $secondary;
  top: -1px;
  right: 0.1em;
  height: 7px;
  width: 7px;
  transform: rotate(45deg);
}
